import { Component } from '@angular/core';
import { ProductId, ProductTranslation } from '@frontend/common/src/lib/util';
import { AppSwitcherService } from './app-switcher.service';

@Component({
  selector: 'wrt-app-switcher',
  templateUrl: './app-switcher.component.html',
  providers: [AppSwitcherService],
})
export class AppSwitcherComponent {
  ProductTranslation = ProductTranslation;
  ProductId = ProductId;

  constructor(public srv: AppSwitcherService) {}
}
