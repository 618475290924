<mat-sidenav-container autosize>
  <mat-sidenav
    fixedInViewport="true"
    mode="side"
    opened
    [ngStyle]="{ width: (srv.isSidenavOpen$ | async) ? '200px' : '53px' }"
  >
    <ng-container
      *ngIf="srv.isSidenavOpen$ | async; then openTmpl; else closedTmpl"
    ></ng-container>

    <!-- OPEN -->
    <ng-template #openTmpl>
      <div id="open" class="lists-wrapper">
        <div class="logo">
          <img
            src="/assets/centrak.svg"
            title="{{ ProductTranslation[ProductId.Workflow] }} ({{ version }})"
          />
        </div>

        <mat-nav-list class="wrapped-list">
          <mat-list-item>
            <div class="item-content" [matMenuTriggerFor]="appSwitcherMenu">
              <mat-icon>apps</mat-icon>
              <span
                class="mat-body-strong"
                [title]="ProductTranslation[ProductId.Workflow]"
                >{{ ProductTranslation[ProductId.Workflow] }}</span
              >
            </div>
          </mat-list-item>
        </mat-nav-list>

        <mat-nav-list>
          <mat-list-item (click)="openPatientSearch()">
            <div class="item-content">
              <mat-icon>search</mat-icon>
              <span class="mat-body-strong" title="Search" i18n-title i18n>Search</span>
            </div>
          </mat-list-item>
          <a mat-list-item routerLink="patients" routerLinkActive="active">
            <div class="item-content">
              <mat-icon>people</mat-icon>
              <span class="mat-body-strong" title="Patients" i18n-title i18n
                >Patients</span
              >
            </div>
          </a>
          <a mat-list-item routerLink="rooms" routerLinkActive="active">
            <div class="item-content">
              <mat-icon>room</mat-icon>
              <span class="mat-body-strong" title="Locations" i18n-title i18n
                >Locations</span
              >
            </div>
          </a>
          <a mat-list-item routerLink="staff" routerLinkActive="active">
            <div class="item-content">
              <mat-icon>person</mat-icon>
              <span class="mat-body-strong" title="Staff" i18n-title i18n>Staff</span>
            </div>
          </a>
          <a mat-list-item routerLink="today-stats" routerLinkActive="active">
            <div class="item-content">
              <mat-icon>equalizer</mat-icon>
              <span class="mat-body-strong" title="Stats" i18n-title i18n>Stats</span>
            </div>
          </a>
          <a
            mat-list-item
            routerLink="contact-tracing"
            routerLinkActive="active"
            *ngIf="accessContactTracing$ | async"
          >
            <div class="item-content">
              <mat-icon>connect_without_contact</mat-icon>
              <span class="mat-body-strong" title="Contact Tracing" i18n-title i18n
                >Contact Tracing</span
              >
            </div>
          </a>
          <a
            mat-list-item
            routerLink="reports"
            routerLinkActive="active"
            *ngIf="accessReports$ | async"
          >
            <div class="item-content">
              <mat-icon>show_chart</mat-icon>
              <span class="mat-body-strong" title="Reports" i18n-title i18n>Reports</span>
            </div>
          </a>
        </mat-nav-list>

        <div class="spacer"></div>

        <mat-nav-list class="wrapped-list">
          <mat-list-item (click)="openNotification()">
            <div class="item-content">
              <mat-icon>notifications</mat-icon>
              <span class="mat-body-strong" title="Notifications" i18n-title i18n
                >Notifications</span
              >
              <div class="visual-indicator" *ngIf="hasNotifications$ | async">
                <div></div>
              </div>
            </div>
          </mat-list-item>
        </mat-nav-list>

        <div class="legal">
          <p class="mat-caption">
            <span>&copy; {{ currentDate }} CenTrak, Inc.</span> <br />
            <span i18n>All rights reserved.</span>
          </p>
          <button mat-icon-button (click)="srv.toggleState()">
            <mat-icon>chevron_left</mat-icon>
          </button>
        </div>
      </div>
    </ng-template>
    <!-- / OPEN -->

    <!-- CLOSED -->
    <ng-template #closedTmpl>
      <div id="closed" class="lists-wrapper">
        <div
          class="logo"
          matTooltip="{{ ProductTranslation[ProductId.Workflow] }} ({{ version }})"
        >
          <img src="/assets/iso.svg" />
        </div>

        <mat-nav-list class="wrapped-list">
          <mat-list-item [matTooltip]="ProductTranslation[ProductId.Workflow]">
            <div class="item-content" [matMenuTriggerFor]="appSwitcherMenu">
              <mat-icon>apps</mat-icon>
            </div>
          </mat-list-item>
        </mat-nav-list>

        <mat-nav-list>
          <mat-list-item
            matTooltip="Search"
            i18n-matTooltip
            (click)="openPatientSearch()"
          >
            <div class="item-content">
              <mat-icon>search</mat-icon>
            </div>
          </mat-list-item>
          <a
            mat-list-item
            routerLink="patients"
            routerLinkActive="active"
            matTooltip="Patients"
            i18n-matTooltip
          >
            <div class="item-content">
              <mat-icon>people</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            routerLink="rooms"
            routerLinkActive="active"
            matTooltip="Locations"
            i18n-matTooltip
          >
            <div class="item-content">
              <mat-icon>room</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            routerLink="staff"
            routerLinkActive="active"
            matTooltip="Staff"
            i18n-matTooltip
          >
            <div class="item-content">
              <mat-icon>person</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            routerLink="today-stats"
            routerLinkActive="active"
            matTooltip="Stats"
            i18n-matTooltip
          >
            <div class="item-content">
              <mat-icon>equalizer</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            routerLink="contact-tracing"
            routerLinkActive="active"
            matTooltip="Contact Tracing"
            i18n-matTooltip
            *ngIf="accessContactTracing$ | async"
          >
            <div class="item-content">
              <mat-icon>connect_without_contact</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            routerLink="reports"
            routerLinkActive="active"
            matTooltip="Reports"
            i18n-matTooltip
            *ngIf="accessReports$ | async"
          >
            <div class="item-content">
              <mat-icon>show_chart</mat-icon>
            </div>
          </a>
        </mat-nav-list>

        <div class="spacer"></div>

        <mat-nav-list class="wrapped-list">
          <mat-list-item
            (click)="openNotification()"
            matTooltip="Notifications"
            i18n-matTooltip
          >
            <div class="item-content">
              <mat-icon>notifications</mat-icon>
              <div class="visual-indicator" *ngIf="hasNotifications$ | async">
                <div></div>
              </div>
            </div>
          </mat-list-item>
        </mat-nav-list>

        <div class="legal">
          <button mat-icon-button (click)="srv.toggleState()">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </ng-template>
    <!-- / CLOSED -->
  </mat-sidenav>

  <mat-sidenav-content>
    <wrt-toolbar></wrt-toolbar>
    <div id="router-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- APP SWITCHER MENU -->
<mat-menu #appSwitcherMenu="matMenu">
  <wrt-app-switcher></wrt-app-switcher>
</mat-menu>
<!-- / APP SWITCHER MENU -->
